<template>
  <div class="col col-12 pa-0 input-form">
    <div>
      <span v-if="field.title" class="label"><b>{{$t(field.title)}}</b><span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
      <div id="mytoolbar"></div>
      <Editor
        v-if="active"
        :placeholder="field.title ? $t(field.title) : ''"
        :api-key="apiKey"
        v-model="model[fieldName]"
        :init="TINY_OPTIONS"
        :key="uuid"
        @input="focusEditor"
        ref="editor"
      />
      <div class="d-flex justify-space-between">
        <!-- Si c'est du unicode, les sms ne font que 70 chars -->
        <div v-if="field.smsCountText" class="mt-2"><b :class="MsgNumber == 1 ? 'black--text': (MsgNumber > 7 ? 'error--text' : 'warning--text')">
          {{ $t(field.smsCountText)+" : "+ MsgNumber }} <span v-if="MsgNumber > 7">({{$t('tooManyCreditUsed')}})</span></b><br/>
          <b >{{ $t('ifmarketing') }} : {{ MsgNumberMarketing }}</b> ({{ $t('marketingSMSaddStopExplanation') }})
        </div>
        <div v-if="field.charactCountText" class="mt-2">
          {{ $t(field.charactCountText)+" : "+MsgRealLength  }}
        </div>
      </div>
      <div v-if="field.smsCountText && model[fieldName] != '' && containsNonGsmCharacters(htmlEntityDecodeAndStripTags(model[fieldName]).trim())">
        {{ $t('smsUnicodeBecause') }} : 
        <span v-html="messageWithProblem(htmlEntityDecodeAndStripTags(model[fieldName]).trim())">
      </span>
    </div>
      <v-textarea 
      :label="field.title ? $t(field.title) : ''"
      v-model="model[fieldName]"
      class="hiddenTextarea mt-2"
      v-bind="field.props"
      :rules=[validate]
      ></v-textarea>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import GenericDataService from '@/services/GenericDataService';
import validate from '../functions/validations'

const TINY_OPTIONS = {
  selector: "textarea#custom-menu-item",
  height: 200,
  toolbar: false,
  plugins: "link image code",
  forced_root_block : false,
  fixed_toolbar_container_target: document.getElementById('mytoolbar'),
  menubar: "custom",
  menu: {
    custom: {
      title: "Insérer une variable",
      items: "",
    },
  },
};

export default {
  props: ["model", "fieldName", "field"],
  components: { Editor },
  mixins: [validate],
  data() {
    return {
      TINY_OPTIONS,
      uuid: Math.random(1000),
      active:false,
      variables: [],
      apiKey: process.env.VUE_APP_TINY_MCE,
      init_instance_callback: function(editor) {
        editor.on('click', function(e) {
        })
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      // this.active = true;
    });
    
  },
  computed: {
    MsgNumberMarketing(){
      return Math.ceil((this.MsgRealLength + 11) / 
        (this.containsNonGsmCharacters(this.htmlEntityDecodeAndStripTags(this.model[this.fieldName]).trim()) 
        ? (this.MsgRealLength <= 70 ? 70 : 67) 
        : (this.MsgRealLength <= 160 ? 160 : 153)))
    },
    MsgNumber(){
      return Math.ceil(this.MsgRealLength / 
        (this.containsNonGsmCharacters(this.htmlEntityDecodeAndStripTags(this.model[this.fieldName]).trim()) 
        ? (this.MsgRealLength <= 70 ? 70 : 67) 
        : (this.MsgRealLength <= 160 ? 160 : 153)))
    },
    MsgRealLength(){
      return this.htmlEntityDecodeAndStripTags(this.model[this.fieldName]).trim().replace(/([\\\[\]\{\}~^\|€])/g, "--").length
    },
    mediaUrl(){
        return this.$store.state.auth.currentUser.accountParameters.mediaUrl
    },
    mediaApiKey(){
        return this.$store.state.auth.currentUser.accountParameters.mediaApiKey
    },
  },
  created() {
    this.TINY_OPTIONS.height = this.field.props.height ? this.field.props.height : 200;
    this.TINY_OPTIONS.menubar = this.field.props.menubar ? this.field.props.menubar : "custom";

    if (this.field.props.paste_as_text) {
        this.TINY_OPTIONS.plugins = [ "paste" ];
        this.TINY_OPTIONS.paste_preprocess = function(plugin, args) {
            args.content = new DOMParser().parseFromString(args.content, 'text/html').body.textContent;
        }
    }

    let url = null;
    if(this.model['retroplanning_id']) // Ajoute le retroplanning_id si il existe
        url = "?retroplanning_id=" + this.model['retroplanning_id'];
    if(this.model['id']) // Ajoute le template id si il existe
        url = (url ? (url + '&') : ('?')) + "template_id=" + this.model['id'];
    if(this.field.props.variable)
        url = ""
      
    if(url || this.field.props.variable) {
        let payload = {}
        if(this.field.props.contexts && this.field.props.contexts.length > 0)
           payload.contexts = this.field.props.contexts

        if(this.field.props.operationId)
          payload.operationId = this.field.props.operationId
        
        GenericDataService.postData("/template/getAvailableVariables" + url, payload).then((response) => {
            let vm = this;
            let variables = response.data.data;
            vm.variables = variables;

            let menuItemsText = "";
            for (var i in variables) {
                menuItemsText += i + " ";
            }
        
            if(this.field.props && this.field.props.variable == true) {
                vm.TINY_OPTIONS.menu.custom = {}
                vm.TINY_OPTIONS.menu.custom.title = "Insérer une variable"
                vm.TINY_OPTIONS.menu.custom.items = menuItemsText;
            
                vm.TINY_OPTIONS.setup = function (editor) {
                    editor.on('ExecCommand', function(e) {
                      //if(e.command == "mceFocus"){
                        e.preventDefault();
                        
                          e.stopPropagation();
                         e.stopImmediatePropagation()
                      //}
                    });


      

                    let variables = vm.getTinyVariables(vm.variables);

                    for (let id in variables){
                        if(variables[id].hasOwnProperty('list')) {
                            editor.ui.registry.addNestedMenuItem(id, { text: variables[id].text, getSubmenuItems: function () { return variables[id].list; } });
                        } else if(variables[id].hasOwnProperty('code')) {
                            editor.ui.registry.addMenuItem(id, 
                            { text: variables[id].text, 

                              onAction: function (evt) { 
                                editor.insertContent(variables[id].code); 
                                vm.uuid++;
                                return true
                              } 
                            });
                        }
                    }
                };
            } else {
                vm.TINY_OPTIONS.menu = {}
            }

            if(this.field.props.toolbar) vm.TINY_OPTIONS.toolbar = "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent";
            else vm.TINY_OPTIONS.toolbar = false;

            if(this.field.props.toolbar) {
                if(typeof this.field.props.toolbar === 'string')
                    vm.TINY_OPTIONS.toolbar += this.field.props.toolbar;
            }

            if(vm.TINY_OPTIONS.toolbar && vm.TINY_OPTIONS.toolbar.includes('image')) {
                vm.TINY_OPTIONS.file_picker_types = 'image';
                vm.TINY_OPTIONS.file_picker_callback = this.image_picker_callback;
            }

            this.active = true;
            this.$forceUpdate()
            
            
        });
    } else {
        this.TINY_OPTIONS.menu = {};
        this.$forceUpdate();
        this.active = true;
    }
  },
  methods: {
    image_picker_callback(callback, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        let self = this;

        input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
                let jsonData = {
                    'title': file.name,
                    'base64': reader.result,
                };

                GenericDataService.postData(self.mediaUrl + "upload?key=" + self.mediaApiKey, jsonData).then((response) => {
                    if(response.data.success) {
                        callback(response.data.media.url, { title: file.name })
                    }
                });
            };

            reader.readAsDataURL(file);
        };

        input.click();
    },
    containsNonGsmCharacters(str) {      
        // Standard GSM characters
        var gsmRegex = /^[A-Za-z0-9\s\r\n@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()+,-.\/:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\*\\\[~\]|€]+$/;

        // Test if the string contains non-standard GSM characters
        return !gsmRegex.test(str);
    },
    messageWithProblem(str){
      return str.replace(/([^A-Za-z0-9\s\r\n@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()+,-.\/:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\*\\\[~\]|€])/g, "<b style='color:red;'>[$1]</b>")
    },
    focusEditor(){
      this.$refs['editor'].$el.addEventListener('focusin', e => e.stopPropagation())
    },
    getTinyVariables(variables) { 
        let tinyVariables = [];
        const vm = this
        const callback_func = function(index, variables) {
            if(typeof variables === 'object' && variables.hasOwnProperty('items')) {
                for (var i in variables.items) {
                    let id = index + "_" + i;
                    tinyVariables[index]['list'].push(id);
                    if(typeof variables.items[i] === 'object' && variables.items[i].hasOwnProperty('items')) {
                        tinyVariables[id] = { "text": (variables.items[i].to_trad ? vm.$t(variables.items[i].name) : variables.items[i].name), "list": [] };
                        callback_func(id, variables.items[i]);
                    } else {
                      if(variables.items[i].to_trad)
                        tinyVariables[id] = { "text": vm.$t(variables.items[i].label), "code": variables.items[i].value };
                      else
                        tinyVariables[id] = { "text": variables.items[i].label, "code": variables.items[i].value };
                    }
                }
            }
        }

        for (var i in variables) {
            tinyVariables[i] = { "text": variables[i].to_trad ? this.$t(variables[i].name) : variables[i].name, "list": [] };
            callback_func(i, variables[i]);
        }

        return tinyVariables;
    },
    htmlEntityDecodeAndStripTags(text) {
        if(text) {
            // Supprime les balises HTML
            var strippedText = text.replace(/(<([^>]+)>)/gi, '');
            
            // Crée un élément textarea pour le décodage des entités
            var textarea = document.createElement('textarea');
            textarea.innerHTML = strippedText;
            var decodedText = textarea.value;
        
            return decodedText;
        }
        
        return "";
    }

  }
};
</script>

<style lang="scss">
  .tox.tox-tinymce {
    border-radius: 4px 4px 0 0;
    border: none;
    border-bottom: 1.25px solid rgba(0, 0, 0, 0.42);
    .tox-edit-area__iframe, .tox-menubar, .tox-statusbar, .tox-toolbar__primary {
      background-color: rgba(0, 0, 0, 0.06);
    }
    &:hover {
      border-bottom: 1.25px solid rgba(0, 0, 0, 0.87);
      .tox-edit-area__iframe, .tox-menubar, .tox-statusbar, .tox-toolbar__primary {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
    .tox-mbtn {
      cursor:pointer;
      .tox-mbtn__select-label {
        cursor:pointer;
      }
    }
    // .tox-statusbar {
    //   display: none;
    // }
  }
  .hiddenTextarea {
    .v-input__control {
        min-height: 24px !important;
    }
    .v-input__slot {
      display:none;
    }
  }
</style>